<div class="modal-dialog modal-notify modal-info">
	<div class="modal-content">
		<div class="modal-header">
			<h1 class="heading lead">
				{{ title }}
			</h1>
			<button [mat-dialog-close] type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true" class="text-white">&times;</span>
			</button>
		</div>

		<div mat-dialog-content class="modal-body">
			<div class="text-center">
				<p>{{ text }}</p>
			</div>
		</div>
	</div>
</div>
