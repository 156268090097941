import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-common-dialog',
	templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.css'],
  styles: [`
    ::ng-deep .mat-dialog-container{
      padding: 0 !important;
      overflow: hidden;
    }
  `]
})

export class CommonDialogComponent {

  public title: string;
  public text: string;

  constructor(private dialogRef: MatDialogRef<CommonDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any) {

    this.title = data.title;
    this.text = data.text;

  }

  close() {
    this.dialogRef.close();
  }

}
